import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  generateLink(app: string): string {
    const current = window.location.href.split(".");
    current[0] = app;
    current[current.length - 1] = "com";
    return current.join(".");
  }
}
