<div class="aside">
  <ul class="link-list">
    <li>
      <a href="https://{{generateLink('jenkins')}}/">Jenkins</a>
    </li>
    <li>
      <a href="https://{{generateLink('sonar')}}/">Sonarqube</a>
    </li>
    <li>
      <a href="https://{{generateLink('archery')}}/">Archery Sec</a>
    </li>
    <li>
      <a href="https://{{generateLink('dtrack')}}/">Dependency Track</a>
    </li>
    <li>
      <a href="https://{{generateLink('harbor')}}/">Docker Hub</a>
    </li>
    <li>
      <a href="https://{{generateLink('npm')}}/">NPM Registry</a>
    </li>
    <li>
      <a href="https://{{generateLink('vault')}}/ui/vault/auth?with=oidc">Secret Vault</a>
    </li>
    <li>
      <a href="https://{{generateLink('argo')}}/">ArgoCD</a>
    </li>
    <li>
      <a href="https://{{generateLink('login')}}/auth/admin/Synergybis/console">User Management</a>
    </li>
    <li>
      <a href="https://{{generateLink('kiali')}}/kiali/">Istio</a>
    </li>
  </ul>
</div>

<div class="blank">
  <h1>Synergy DevSecOps</h1>
  <h2>continuous integration platform tools directory</h2>
</div>
